import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Amplify } from 'aws-amplify';

const version = '0.0.0';

try {
  Amplify.configure(environment.amplify);
} catch (ex) {
  console.error(ex);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() =>
    console.log(
      `Loading ${version} for the ${environment.current} environment...`
    )
  )
  .catch(err => console.error(err));
