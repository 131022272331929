import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { helpReducer } from './store/help.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HelpEffects } from './store/help.effects';
import { HelpComponent } from './components/help/help.component';
import { HelpStoreService } from './services/help-store.service';


@NgModule({
  declarations: [
    HelpComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature('help', helpReducer),
    EffectsModule.forFeature([HelpEffects])
  ],
  exports: [
    HelpComponent
  ],
  providers: [
    HelpStoreService
  ]
})
export class HelpModule { }
