import { Action, createReducer, on } from '@ngrx/store';
import { UpdateHelpStoreSuccess } from './help.actions';
import { HelpStore } from '../interfaces/help-store.interface';


export const initialSignInState: HelpStore = {
  action: null
}

const reducer = createReducer(
  initialSignInState,
  on(UpdateHelpStoreSuccess, (state, { action }) => Object.assign({}, state, { action: action }))
)

export function helpReducer(state: HelpStore | undefined, action: Action) {
  return reducer(state, action);
}