import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpdateHelpStore } from '../store/help.actions';
import { helpActionSelector } from '../store/help.selectors';

@Injectable({
  providedIn: 'root'
})
export class HelpStoreService {

  constructor(private store: Store) { }

  public selectHelpAction() {
    return this.store.select(helpActionSelector);
  }

  public dispatchUpdateHelpStore(action: string) {
    this.store.dispatch(UpdateHelpStore({ action: action }));
  }
}
