import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { PreviousRoute } from "../../../classes/previous-route.class";
import { modalHeaderPreviousRouteContext } from "../constants/modal-header.constants";
import { HeaderService } from "../services/header.service";


@Injectable({
  providedIn: 'root'
})

export class ModalHeaderLocationGuard implements CanActivate {
  constructor(private headerCommonService: HeaderService, private router: Router) { }

  canActivate(): boolean {
    const previousRouteRouter = new PreviousRoute(this.router, modalHeaderPreviousRouteContext);
    previousRouteRouter.dispatchStoreUpdate(this.headerCommonService.dispatchUpdateStore.bind(this.headerCommonService));
    return true;
  }
};
