import { Injectable } from '@angular/core';
import { AmplifyApiEnvironment } from '../classes/amplify-api-environment';
import { API } from 'aws-amplify';
import { from, Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { AwsAuthService } from '@nx/auth';

@Injectable({
  providedIn: 'root'
})
export class AmplifyApiService {

  constructor(private amplifyApiEnv:AmplifyApiEnvironment, private awsAuthService:AwsAuthService) { }

  public publicPost(path:string, body:Object):Observable<any> {
    return from(this.publicPostRequest(path, body)).pipe(take(1));
  }

  public publicGet(path:string, queryParameters?: object):Observable<any> {
    return from(this.publicGetRequest(path, queryParameters)).pipe(take(1));
  }

  public privateGet<T = any>(path:string):Observable<T> {
    return from(this.privateGetRequest(path)).pipe(take(1));
  }

  public privatePost<T = any>(path:string, body:Object):Observable<T> {
    return from(this.privatePostRequest(path, body)).pipe(take(1));
  }

  public privatePatch(path: string, body: Object): Observable<any> {
    return from(this.privatePatchRequest(path, body)).pipe(take(1));
  }

  public privatePut(path: string, body: any): Observable<any> {
    return from(this.privatePutRequest(path, body)).pipe(take(1));
  }

  private async publicPostRequest(path: string, body: Object):Promise<any> {
    return await API.post('public-api', path, {
      headers: {
        "x-api-key": this.amplifyApiEnv.amplify.API.apiKey
      },
      body: body
    });
  }

  private async publicGetRequest(path: string, queryParameters?: object): Promise<any> {
    return await API.get('public-api', path, {
      headers: {
        "x-api-key": this.amplifyApiEnv.amplify.API.apiKey,
      },
      ...queryParameters && { queryStringParameters: queryParameters }
    });
  }

  private async privateGetRequest(path: string): Promise<any> {
    const currentUser = await this.awsAuthService.getFullCurrentUser();
    return await API.get('public-api', path, {
      headers: {
        Authorization:
          'Bearer ' + currentUser.signInUserSession.idToken.jwtToken,
        "x-api-key": this.amplifyApiEnv.amplify.API.apiKey
      },
    });
  }

  private async privatePostRequest(path: string, body: Object):Promise<any> {
    const currentUser = await this.awsAuthService.getFullCurrentUser();
    return await API.post('public-api', path, {
      headers: {
        Authorization:
          'Bearer ' + currentUser.signInUserSession.idToken.jwtToken,
        "x-api-key": this.amplifyApiEnv.amplify.API.apiKey
      },
      body: body
    });
  }

  private async privatePatchRequest(path: string, body: Object): Promise<any> {
    const currentUser = await this.awsAuthService.getFullCurrentUser();
    return await API.patch('public-api', path, {
      headers: {
        Authorization:
          'Bearer ' + currentUser.signInUserSession.idToken.jwtToken,
        'x-api-key': this.amplifyApiEnv.amplify.API.apiKey
      },
      body: body
    });
  }

  private async privatePutRequest(path: string, body: Object): Promise<any> {
    const currentUser = await this.awsAuthService.getFullCurrentUser();
    return await API.put('public-api', path, {
      headers: {
        Authorization:
          'Bearer ' + currentUser.signInUserSession.idToken.jwtToken,
        'x-api-key': this.amplifyApiEnv.amplify.API.apiKey
      },
      body: body
    });
  }

}
