import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as HelpActions from './help.actions';
import { map } from "rxjs/operators";

@Injectable()
export class HelpEffects {

  constructor(private actions$: Actions) { }

  updateHelpStore$ = createEffect(() => this.actions$.pipe(
    ofType(HelpActions.UpdateHelpStore),
    map(action => HelpActions.UpdateHelpStoreSuccess({ action: action.action }))
  ));
}